/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react'

// Images
import pin from 'img/pin.svg'

// MAP options
import GoogleMapsSettings from 'services/GoogleMapsSettings'

// Third Party
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import styled from 'styled-components'
import getDistance from 'geolib/es/getDistance'

const StyledMarker = styled(Marker)`
  background-color: ${(props) => props.theme.color.face.contrast};
`

export class MapContainer extends React.Component<any> {
  constructor(props: any) {
    super(props)
    this.state = {
      refresh: false,
    }
  }

  public mapLoaded(map: any) {
    map.setOptions({
      styles: GoogleMapsSettings,
    })
  }

  render() {
    const b = new this.props.google.maps.LatLngBounds()

    for (let i = 0; i < this.props.points.length; i += 1) {
      b.extend(this.props.points[i].position)
    }

    this.props.points.forEach((point: any) => {
      let range: number = 100000000
      this.props.points
        .filter((p: any) => p.name !== point.name)
        .forEach((otherPoint: any) => {
          const distance = getDistance(
            {
              latitude: point.position.lat,
              longitude: point.position.lng,
            },
            {
              latitude: otherPoint.position.lat,
              longitude: otherPoint.position.lng,
            },
            10
          )
          if (distance !== 0 && distance < range) {
            range = distance
          }
        })
      // eslint-disable-next-line no-param-reassign
      point.range = range
    })

    const calculatePinSize = (distance: number, length: number) => {
      if (length > 5) {
        if (distance < 100000) {
          return 10
        }

        if (distance < 1000000) {
          return 15
        }

        return 35
      }

      return 35
    }

    if (b === null) {
      return null
    }

    return (
      <Map
        // @ts-ignore
        key={this.state.refresh ? `yes-map` : `no-map`}
        // @ts-ignore
        zoomControl={false}
        maxZoom={5}
        zoom={5}
        scrollWheel={false}
        draggable={false}
        streetViewControl={false}
        mapTypeControl={false}
        fullscreenControl={false}
        google={this.props.google}
        initialCenter={{
          lat: 51.5221324,
          lng: 4.4477466,
        }}
        containerStyle={{
          width: '100%',
          maxWidth: '100%',
        }}
        onReady={(mapProps, map) => this.mapLoaded(map)}
        bounds={b}
      >
        {this.props.points.map((point: any) => (
          <StyledMarker
            // @ts-ignore
            title={point.title}
            name={point.name}
            position={point.position}
            icon={{
              url: pin,
              // @ts-ignore
              scaledSize: new google.maps.Size(
                calculatePinSize(point.range, this.props.points.length),
                calculatePinSize(point.range, this.props.points.length)
              ),
            }}
          />
        ))}
      </Map>
    )
  }
}

export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyAmr5r8d6LKmu418qfE8hwGSk30of_yRRA',
  language: 'en-US',
  // @ts-ignore
}))(MapContainer)
