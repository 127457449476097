import React from 'react'

// Third Party
import { motion } from 'framer-motion'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledSingleReseller = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 40px;
`

const ResellerInfo = styled.div`
  min-height: 100px;

  & p {
    line-height: 20px;
    font-size: ${(props) => props.theme.font.size[14]};
  }
`

interface SingleResellerProps {
  // eslint-disable-next-line
  fields?: GatsbyTypes.WpPage_Flexcontent_Flex_FindResellerForm_resellers
  className?: string
}

const SingleReseller: React.FC<SingleResellerProps> = ({
  fields,
  className = '',
}) => (
  <div className={className}>
    <StyledSingleReseller
      initial={{ borderRadius: 17, opacity: 0 }}
      animate={{ opacity: 1 }}
      whileHover={{ borderRadius: 0 }}
      exit={{ opacity: 0 }}
    >
      <ResellerInfo>
        <ParseContent content={fields?.description} />
      </ResellerInfo>
    </StyledSingleReseller>
  </div>
)

export default SingleReseller
