import React, { useState, useEffect } from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import CustomMap from 'components/flex/Reseller/CustomMap'
import GravityForm from 'components/shared/GravityForm'
import SingleReseller from 'components/flex/Reseller/SingleReseller'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { DebounceInput } from 'react-debounce-input'

const MapContainer = styled.div`
  min-height: 550px;
  position: relative;
  pointer-events: none;
  
  > div > div > div > div {
    border-radius: 29px;
  }

  @media screen and (max-width: 576px) {
    min-height: 400px;
    width: 130%;
    left: -15%;
  }

  @media screen and (max-width: 400px) {
    min-height: 350px;
    width: 120%;
    left: -10%;
  }
`

const PendingContainer = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 29px;
  border-bottom-right-radius: 29px;
  padding: 40px 160px 40px 160px;
  margin-top: -110px;
  position: relative;
  z-index: 1;

  & h2 {
    padding-bottom: 20px;
  }

  & p {
    padding: 0 100px 0 100px;
    line-height: 20px;
  }

  @media screen and (max-width: 1199px) {
    padding: 40px 80px 40px 80px;
  }

  @media screen and (max-width: 991px) {
    padding: 40px 40px 0px 40px;

    & p {
      padding: 0 20px 0 20px;
    }
  }

  @media screen and (max-width: 576px) {
    & h2 {
      font-size: ${(props) => props.theme.font.size[24]};
      line-height: 24px;
    }
  }

  @media screen and (max-width: 400px) {
    padding: 20px 20px 0 20px;
  }
`

const SendContainer = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.main};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 40px 40px 40px 40px;
  margin: -140px 200px 0 200px;
  position: relative;
  z-index: 1;

  & h2 {
    padding-bottom: 30px;
    font-size: ${(props) => props.theme.font.size[20]};
    line-height: 24px;
    color: ${(props) => props.theme.color.text.light};
  }

  & p {
    padding: 0 100px 0 100px;
    line-height: 20px;
  }

  @media screen and (max-width: 1199px) {
    margin: -140px 100px 0 100px;
  }

  @media screen and (max-width: 991px) {
    margin: -140px 0 0 0;
  }

  @media screen and (max-width: 576px) {
    padding: 25px 20px 25px 20px;

    & h2 {
      padding-bottom: 20px;
    }
  }
`

const SubContainer = styled.div`
  padding: 100px 0 50px 0;

  & p {
    line-height: 25px;
  }

  @media screen and (max-width: 400px) {
    padding: 50px 0 5px 0;
  }
`

const Input = styled.div`
  & input {
    width: 100%;
    border-radius: 9px;
    border: none;
    outline: none;
    padding: 15px 30px 15px 30px;

    &::placeholder {
      color: ${(props) => props.theme.color.text.dark};
      font-style: italic;
    }
  }
`

const StyledDiv = styled.div`
@media (min-width: 992px){
  height: 550px;

  > div {
    
      width: 1110px;
    
    height: 100%;
    position: relative;
    margin: auto;
  }
  }
`

interface FindResellerFormProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_FindResellerForm
  location?: any
}

const FindResellerForm: React.FC<FindResellerFormProps> = ({ fields }) => {
  const [formStatus, setFormStatus] = useState('pending')
  const [country, setCountry] = useState('')
  const [resellers, setResellers] = useState(fields.resellers)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRefresh(true)
    }, 1000)
  }, [])

  useEffect(() => {
    const rs = fields.resellers?.filter(
      (reseller) =>
        reseller?.country
          ?.split(',')
          .filter(
            (sp) => sp.toLowerCase().indexOf(country.toLowerCase()) !== -1
          ).length !== 0
    )

    setResellers(rs)
  }, [country])

  const statuses: any = {
    pending: (
      <PendingContainer
        id="pending-container"
        key="pending-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, x: 500 }}
      >
        <ParseContent content={fields.description} />
        <GravityForm
          setCountry={setCountry}
          setFormStatus={setFormStatus}
          id={3}
        />
      </PendingContainer>
    ),
    send: (
      <motion.div
        id="send-container"
        key="send-container"
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.8 } }}
        exit={{ opacity: 0 }}
      >
        <SendContainer>
          <ParseContent content={fields.smallDescription} />
          <Input className="d-flex flex-md-nowrap flex-wrap justify-content-between">
            <DebounceInput
              minLength={2}
              debounceTimeout={400}
              value={country}
              onChange={(e: any) => setCountry(e.target.value)}
              placeholder="Search for country"
            />
          </Input>
        </SendContainer>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row pt-lg-5 pt-4 mt-2">
              <AnimatePresence>
                {resellers?.map((reseller, index) => (
                  <SingleReseller
                    className="col-sm-6 col-lg-4 pb-4 mb-2"
                    key={index}
                    fields={reseller}
                  />
                ))}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </motion.div>
    ),
  }

  return (
    <div>
      <MapContainer>
        <StyledDiv className="bg-main">
          <CustomMap points={refresh ? resellers : []} />
        </StyledDiv>
      </MapContainer>
      <div className="container py-5">
        <motion.div>
          <AnimatePresence>{statuses[formStatus]}</AnimatePresence>
        </motion.div>
        <SubContainer className="row justify-content-center">
          <div className="col-lg-8">
            {/* <ParseContent content={fields.description2} /> */}
          </div>
        </SubContainer>
      </div>
    </div>
  )
}

export default FindResellerForm
